import { Fragment, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTasks } from "../../api/auth";
import Divider from "../../components/common/divider";
import PageContentWrapper from "../../components/common/pageContentWrapper";
import PageWrapper from "../../components/common/pageWrapper";
import Typography from "../../components/common/typography";
import AdvertisementTask from "../../components/tasks/advertisementTask";
import FindObjectTask from "../../components/tasks/findObjectTask";
import List from "../../components/tasks/list";
import { handleErrors } from "../../core/helpers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setUserTasks } from "../../store/reducers/accountSlice";
import { IFindOjbectTaskItem, IProcessTask, ITaskItem, IUserTask } from "../../types/types";
import TaskModule from "../../components/tasks/taskModule";

interface normalizedTask {
  items: ITaskItem[]
  completed: number
}

enum TaskStatus {
  Completed = 'Completed',
  RewardClaimed = 'RewardClaimed'
}

const getInitialData = (): { [key: string]: normalizedTask } => ({
  'season': {
    items: [], completed: 0,
  },
  'subscribe': {
    items: [], completed: 0,
  },
  'watch': {
    items: [], completed: 0,
  },
  'referral': {
    items: [], completed: 0,
  },
  'progress': {
    items: [], completed: 0,
  },
  'promo': {
    items: [], completed: 0,
  },
  'support': {
    items: [], completed: 0,
  },
});

const Tasks = () => {
  const tasks = useAppSelector(state => state.settings.settings.tasks);
  const userTasks = useAppSelector(state => state.account.userTasks);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userTasksMap = useMemo(() => {
    return new Map(userTasks.map((item) => [item.taskId, item]));
  }, [userTasks]);

  const normalizedTasks = useMemo<ITaskItem[]>(() => {
    const taskItems = tasks.map((item) => ({
      ...userTasksMap.get(item.id) || {} as IUserTask,
      ...item,
    }));
    return taskItems;
  }, [userTasksMap, tasks]);

  const splittedTasks = useMemo(() => {
    return normalizedTasks.reduce((a, c) => {
      switch (c.group) {
        case 'Season':
          a.season.items.push(c);
          break;
        case 'Subscribe':
          a.subscribe.items.push(c);
          break;
        case 'Watch':
          a.watch.items.push(c);
          break;
        case 'Referral':
          a.referral.items.push(c);
          break;
        case 'Progress':
          a.progress.items.push(c);
          break;
        case 'Promo':
          a.promo.items.push(c);
          break;
        case 'Support':
          a.support.items.push(c);
          break;
      }
      if (c.status === TaskStatus.Completed || c.status === TaskStatus.RewardClaimed) {
        const group = c.group.toLowerCase();
        a[group].completed += 1;
      }
      return a;
    }, getInitialData());
  }, [normalizedTasks]);

  const handleProcessTasks = useCallback((task: IProcessTask) => {
    const updatedUserTasks = userTasks.map((item) => item.taskId === task.taskId ? task : item);
    dispatch(setUserTasks(updatedUserTasks));
  }, [dispatch, userTasks]);

  const setup = useCallback(async () => {
    try {
      const userTasks = await getTasks();
      dispatch(setUserTasks(userTasks));
    } catch (err: any) {
      handleErrors(err);
    }
  }, [dispatch]);

  useEffect(() => {
    setup();
  }, [setup]);

  return (
    <PageWrapper>
      <PageContentWrapper>
        <>
          <Typography style={{ textAlign: 'center' }} variant='title2' color='white'>{t('pages.tasks.sections.main.title')}</Typography>
          <AdvertisementTask />
          {splittedTasks.season.items[0] && <FindObjectTask task={splittedTasks.season.items[0] as IFindOjbectTaskItem} />}
          {!!splittedTasks.support.items.length &&
            <List
              title={t('pages.tasks.sections.support.title')}
              secondTitle={t('pages.tasks.sections.support.secondTitle', { taskAmountCompleted: splittedTasks.support.completed, taskAmountTotal: splittedTasks.support.items.length })}
              items={splittedTasks.support.items}
              renderItem={(task, index: number) =>
                <Fragment key={index}>
                  <TaskModule task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.support.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
          {!!splittedTasks.promo.items.length &&
            <List
              title={t('pages.tasks.sections.promo.title')}
              secondTitle={t('pages.tasks.sections.promo.secondTitle', { taskAmountCompleted: splittedTasks.promo.completed, taskAmountTotal: splittedTasks.promo.items.length })}
              items={splittedTasks.promo.items}
              renderItem={(task, index: number) =>
                <Fragment key={index}>
                  <TaskModule task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.promo.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
          {!!splittedTasks.subscribe.items.length &&
            <List
              title={t('pages.tasks.sections.subscribe.title')}
              secondTitle={t('pages.tasks.sections.subscribe.secondTitle', { taskAmountCompleted: splittedTasks.subscribe.completed, taskAmountTotal: splittedTasks.subscribe.items.length })}
              items={splittedTasks.subscribe.items}
              renderItem={(task, index: number) =>
                <Fragment key={index}>
                  <TaskModule task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.subscribe.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
          {!!splittedTasks.watch.items.length &&
            <List
              title={t('pages.tasks.sections.watch.title')}
              secondTitle={t('pages.tasks.sections.watch.secondTitle', { taskAmountCompleted: splittedTasks.watch.completed, taskAmountTotal: splittedTasks.watch.items.length })}
              items={splittedTasks.watch.items}
              renderItem={(task, index: number) =>
                <Fragment key={index}>
                  <TaskModule task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.watch.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
          {!!splittedTasks.referral.items.length &&
            <List
              title={t('pages.tasks.sections.referral.title')}
              secondTitle={t('pages.tasks.sections.referral.secondTitle', { taskAmountCompleted: splittedTasks.referral.completed, taskAmountTotal: splittedTasks.referral.items.length })}
              items={splittedTasks.referral.items}
              renderItem={(task, index: number) =>
                <Fragment key={index}>
                  <TaskModule task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.referral.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
          {!!splittedTasks.progress.items.length &&
            <List
              title={t('pages.tasks.sections.progress.title')}
              secondTitle={t('pages.tasks.sections.progress.secondTitle', { taskAmountCompleted: splittedTasks.progress.completed, taskAmountTotal: splittedTasks.progress.items.length })}
              items={splittedTasks.progress.items}
              renderItem={(task, index: number) =>
                <Fragment key={index}>
                  <TaskModule key={task.id} task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.progress.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
        </>
      </PageContentWrapper>
    </PageWrapper>
  );
}

export default Tasks;
