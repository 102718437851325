import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { useReplenishTimer } from '../../../hooks/useReplenishTimer';
import { useAppSelector } from '../../../store/hooks';
import LeftSideIcon from '../../common/leftSideIcon';
import Typography from '../../common/typography';

import styles from './FindObjectTask.module.scss';

import { ReactComponent as WalletIcon } from '../../../assets/themeIcons/halloween/footer/HalloweenWalletIcon.svg';
import { useTranslation } from 'react-i18next';
import { IFindOjbectTaskItem } from '../../../types/types';
import { useMemo } from 'react';
import { useServerTranslate } from '../../../hooks/useServerTranslate';

const FindObjectTask = ({ task }: { task: IFindOjbectTaskItem }) => {
  const translatedTitle = useServerTranslate(task.titleTranslationKey, 'title');
  const userTasks = useAppSelector(state => state.account.userTasks);
  const lastCandyClaimed = useMemo(() => {
    return userTasks.find((item) => item.taskId === task.id && item.status === 'RewardClaimed')?.updatedAt || '';
  }, [userTasks, task.id]);
  const nextCandyTime = task.repetitionTime * 1000 * 60;
  const nextCandyTimer = useReplenishTimer(lastCandyClaimed, nextCandyTime);
  const formattedAsset = useFormattedAsset(null, task.reward);

  const { t } = useTranslation();

  return (
    <div className={styles.list__wrapper}>
      {/* <div className={styles.title__container}>
        <Typography style={{ marginLeft: '16px' }} variant='subheadline1' color='white'>Promo task</Typography>
      </div> */}
      <div className={styles.task_item__wrapper}>
        <div className={styles.task_item}>
          <div style={{ maxWidth: '56px' }}>
            <LeftSideIcon type='big' icon={<WalletIcon className='importantSvg' style={{ fill: "white" }} />} />
          </div>
          <div className={`${styles.task_info__wrapper} flex-break-word`}>
            <div className={styles.task_main_info__wrapper}>
              <Typography variant='body1' color='primary'>{translatedTitle}</Typography>
              <Typography variant='subheadline2' color='white'>+ {formattedAsset.amount} {formattedAsset.name}</Typography>
            </div>
            {nextCandyTimer && <Typography variant='caption1' color='secondary'>{t('nextCandy', { nextCandyTimer })}</Typography>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindObjectTask;
