import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ReactComponent as CopyIcon } from '../../../assets/common/CopyIcon.svg';
import IconButton from '../iconButton';

interface IProps {
  data: any,
  sx?: any,
  message?: string,
}

const CopyButton = ({ data, sx, message }: IProps) => {
  const { t } = useTranslation();
  const copy = useCallback(() => {
    navigator.clipboard.writeText(data);
    toast.success(message || t('toasts.defaultCopyText'));
  }, [data, message, t]);
  return (
    <IconButton onClick={copy}>
      <CopyIcon />
    </IconButton>
  );
}

export default CopyButton;
