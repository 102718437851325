import React, { lazy } from "react";
import { toast } from "react-toastify";

const tg = window.Telegram?.WebApp;
const lng = tg?.initDataUnsafe?.user?.language_code;

export function handleErrors(error: any) {
  if (error.errors) {
    error.errors.forEach((item: any) => {
      if (error.statusCode === 400) {
        toast.error(item.message);
      } else {
        console.log(error);
        toast.error(defaultErrorMessage);
      }
    });
  } else if (error.message) {
    if (error.statusCode === 400) {
      return toast.error(error.message);
    } else {
      console.log(error);
      toast.error(defaultErrorMessage);
    }
  }
}

export function openLink(link: string) {
  if (tg.platform === 'ios') {
    window.location.href = link;
  } else {
    window.open(link);
  }
}

export function lazyWithDelay(func: () => Promise<any>, delay: number): React.LazyExoticComponent<any> {
  return lazy(() => {
    return Promise.all([
      func(),
      new Promise(resolve => setTimeout(resolve, delay))
    ]).then(([moduleExports]) => moduleExports);
  });
}

export async function delay(time: number = 0) {
  return new Promise(res => setTimeout(res, time));
}

export const exceptionalStatusCodes = new Set([400, 401, 500]);

export async function repeatable<T>(
  fetchFunc: () => Promise<T>,
  maxRetryCount = 5,
  delayTime: number = 500
) {
  let retryCount = 0;
  let lastErr;
  do {
    try {
      return await fetchFunc();
    } catch (err: any) {
      if (exceptionalStatusCodes.has(err.statusCode)) {
        throw err;
      }
      await delay(delayTime);
      retryCount++;
      lastErr = err;
    }
  } while (retryCount < maxRetryCount);
  throw lastErr;
}

export const defaultErrorMessage = lng === 'ru' ? 'Упс, что-то пошло не так! Попробуйте позже.' : 'Oops, something bad happened! Try again later.';
export const unknownErrorMessage = lng === 'ru' ? 'Неизвестная ошибка' : 'Unknown error';