import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IAccount, IReferal, IReferals, IUserTask } from '../../types/types'
import { getAccount, getReferrals, getTasks } from '../../api/auth';
import { handleErrors } from '../../core/helpers';


export const fetchAccount = createAsyncThunk<IAccount, undefined, { rejectValue: string }>(
  'account/fetchAccount',
  async (_, { rejectWithValue }) => {
    try {
      return await getAccount();
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchReferrals = createAsyncThunk<IReferals, undefined, { rejectValue: string }>(
  'account/fetchReferrals',
  async (_, { rejectWithValue }) => {
    try {
      return await getReferrals();
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchUserTasks = createAsyncThunk<IUserTask[], undefined, { rejectValue: string }>(
  'account/fetchUserTasks',
  async (_, { rejectWithValue }) => {
    try {
      return await getTasks();
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
);
interface AccountState {
  account: IAccount,
  referals: IReferals,
  userTasks: IUserTask[],
  credentials: string,
}

const initialState: AccountState = {
  account: {
    score: 0,
    rewards: 0,
    hasDailyReward: false,
    username: ''
  } as IAccount,
  referals: {
    data: [] as IReferal[],
    total: 0,
  },
  userTasks: [],
  credentials: ''
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUserTasks: (state, action: PayloadAction<IUserTask[]>) => {
      state.userTasks = action.payload;
    },
    setCredentials: (state, action: PayloadAction<string>) => {
      state.credentials = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.account = action.payload;
      })
      .addCase(fetchReferrals.fulfilled, (state, action) => {
        state.referals = action.payload;
      })
      .addCase(fetchUserTasks.fulfilled, (state, action) => {
        state.userTasks = action.payload;
      })
  }
})

export default accountSlice.reducer;
export const { setUserTasks, setCredentials } = accountSlice.actions;