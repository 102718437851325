import React from 'react';
import { IBoostChannelTaskItem, IChangeUsernameTaskItem, IDonateTaskItem, IFindOjbectTaskItem, IProcessTask, IProgressTaskItem, IReferralTaskItem, IShareStoryTaskItem, ISubscribeTaskItem, ITask, IWatchTaskItem } from '../../../types/types';
import FindObjectTask from '../findObjectTask';
import TaskBoostChannelItem from '../taskBoostChannelItem';
import TaskChangeUsernameItem from '../taskChangeUsernameItem';
import TaskDonateItem from '../taskDonateItem';
import TaskProgressItem from '../taskProgressItem';
import TaskReferralItem from '../taskReferralItem';
import TaskShareStoryItem from '../TaskShareStoryItem';
import TaskSubscribeItem from '../taskSubscribeItem';
import TaskWatchItem from '../taskWatchItem';

interface IProps {
  task: ITask,
  onClick: (task: IProcessTask) => void,
}

const TaskModule = ({ task, onClick }: IProps) => {
  switch (task.type) {
    case 'FindObject':
      return <FindObjectTask task={task as IFindOjbectTaskItem} />
    case 'Watch':
      return <TaskWatchItem task={task as IWatchTaskItem} onClick={onClick} />
    case 'Subscribe':
      return <TaskSubscribeItem task={task as ISubscribeTaskItem} onClick={onClick} />
    case 'Progress':
      return <TaskProgressItem task={task as IProgressTaskItem} onClick={onClick} />
    case 'Referral':
      return <TaskReferralItem task={task as IReferralTaskItem} onClick={onClick} />
    case 'ShareStory':
      return <TaskShareStoryItem task={task as IShareStoryTaskItem} onClick={onClick} />
    case 'ChangeUsername':
      return <TaskChangeUsernameItem task={task as IChangeUsernameTaskItem} onClick={onClick} />
    case 'BoostChannel':
      return <TaskBoostChannelItem task={task as IBoostChannelTaskItem} onClick={onClick} />
    case 'Donate':
      return <TaskDonateItem task={task as IDonateTaskItem} onClick={onClick} />
    default:
      return null;
  }
}

export default TaskModule;
