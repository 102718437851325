import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IProcessTask, IShareStoryTaskItem, LinkType } from '../../../types/types';
import TaskItem from '../taskItem';

import { ReactComponent as ShareStoryIcon } from '../../../assets/tasks/ShareStoryIcon.svg';
import useReferralLink from '../../../hooks/useReferralLink';

import StoriesImage from '../../../assets/StoriesImage.jpg';
import { useTranslation } from 'react-i18next';

const tg = window.Telegram?.WebApp;

interface IProps {
  task: IShareStoryTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskShareStoryItem = ({ task, onClick }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const formattedAsset = useFormattedAsset(null, task.reward);
  const referralLink = useReferralLink(LinkType.Copy);
  const { t } = useTranslation();

  const handleShareToStory = useCallback(async () => {
    try {
      if (tg.platform === 'tdesktop' || tg.platform === 'macos') {
        throw new Error('');
      }
      // const isPremium = tg.initDataUnsafe?.user?.is_premium;
      // const options = isPremium ? { widget_link: referralLink, text: `${t('pages.tasks.sections.promo.shareText')} ${referralLink}` } : { text: `${t('pages.tasks.sections.promo.shareText')} ${referralLink}` };
      const options = { text: `${t('pages.tasks.sections.promo.shareText')} ${referralLink}` };
      await tg.shareToStory(
        StoriesImage,
        options
      );
      return true;
    } catch (err: any) {
      toast.error(t('pages.tasks.sections.promo.toasts.errors.usePhoneError'));
      return false;
    }
  }, [t, referralLink]);

  const handleStart = useCallback(async () => {
    try {
      setIsLoading(true);
      const success = await handleShareToStory();
      if (!success) {
        return;
      }
      const res = await processTask(task.id);
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, handleShareToStory, t]);

  const handleVerify = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await processTask(task.id);
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, t]);

  const handleClick = useCallback(() => {
    if (task.operation === 'Start') {
      return handleStart();
    }
    if (task.operation === 'Verify') {
      return handleVerify();
    }
  }, [handleStart, handleVerify, task.operation]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <TaskItem task={task} isLoading={isLoading} status={task.status} leftIcon={<ShareStoryIcon />} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
    </div>
  );
}

export default TaskShareStoryItem;
