import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IDonateTaskItem, IDonateUserTask, IProcessTask, } from '../../../types/types';
import TaskItem from '../taskItem';

import { ReactComponent as TelegramStarIcon } from '../../../assets/tasks/TelegramStarIcon.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks';

interface IProps {
  task: IDonateTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskDonateItem = ({ task, onClick }: IProps) => {
  const userTasks = useAppSelector(state => state.account.userTasks);
  const [isLoading, setIsLoading] = useState(false);
  const formattedAsset = useFormattedAsset(null, task.reward);
  const { t } = useTranslation();
  const donateTask = useMemo(() => userTasks.find((item) => item.taskId === task.id), [task.id, userTasks]);
  const handleStart = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await processTask(task.id) as IDonateUserTask;
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
      window.Telegram.WebApp.openInvoice(res.state.invoiceLink);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, t]);

  const handleVerify = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await processTask(task.id) as IDonateUserTask;
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
      window.Telegram.WebApp.openInvoice(donateTask?.state?.invoiceLink as string);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, t, donateTask?.state?.invoiceLink]);

  const handleClick = useCallback(() => {
    if (task.operation === 'Start') {
      return handleStart();
    }
    if (task.operation === 'Verify') {
      return handleVerify();
    }
  }, [handleStart, handleVerify, task.operation]);

  return (
    <TaskItem task={task} isLoading={isLoading} status={task.status} leftIcon={<TelegramStarIcon />} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
  );
}

export default TaskDonateItem;
