import { repeatable, unknownErrorMessage } from "../core/helpers";
import { IAccount, IApplicationSettings, IFetchedRanks, IProcessTask, IReferals, IReplenish, IStatistics, IUserTask, ITreeSettings, ITranslation, IClaimReferralAsset } from "../types/types";
import { share, clear } from "./apiHelpers";
import { setHubUrl } from "./wallet";

interface ISignatue {
  grantType: 'Signature',
  payload: string,
  walletAddress?: string,
  treeSettings?: ITreeSettings,
}

interface IRefreshToken {
  grantType: 'RefreshToken',
  refreshToken: string,
}

const telegramApiUrl = process.env.REACT_APP_TELEGRAM_API_KEY;

export function doRequest(relativeUrl: string, config: any = {}, isRetry?: boolean): Promise<any> {
  const url = `${telegramApiUrl}/${relativeUrl}`;
  return doCachedRequest(url, config, isRetry);
}

export function getShareKey(url: string, config: any = {}) {
  return `makeApiCall:${url}#${JSON.stringify(config.body)}`;
}

export function doCachedRequest(url: string, config: any = {}, isRetry?: boolean): Promise<any> {
  const shareKey = getShareKey(url, config);
  return share(shareKey, () => request(url, config, isRetry), 0.5);
}

function postData<T>(relativeUrl: string, body?: T, isRestry?: boolean): Promise<any> {
  return doRequest(relativeUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body ? JSON.stringify(body) : undefined
    },
    isRestry);
}

export async function request(url: string, config: any = {}, isRetry?: boolean): Promise<any> {
  config.headers = config.headers || {};
  config.headers["Content-Type"] = "application/json";
  config.headers["ngrok-skip-browser-warning"] = "true";

  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  try {
    const res = await fetch(url, config);
    if (res.status === 401) {
      if (isRetry) {
        removeAccessToken();
        removeRefreshToken();
        return window.location.reload();
      } else {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          await refreshTokens(refreshToken);
          const shareKey = getShareKey(url, config);
          clear(shareKey, -1);
          return doCachedRequest(url, config, true);
        }
      }
    }
    let result;
    if (res.headers.get("Content-Type")?.indexOf("application/json") === 0) {
      result = await res.json();
    }
    if (res.ok) {
      return result;
    }
    throw { errors: result?.errors, message: unknownErrorMessage, statusCode: res.status };
  } catch (err: any) {
    throw err;
  }
}

export async function getToken(payload: ISignatue | IRefreshToken, isRetry?: boolean) {
  const res = await postData(`api/oauth2/token`, payload, isRetry);
  setRefreshToken(res.refreshToken);
  return setAccessToken(res.accessToken);
}

export async function getAuthSettings(): Promise<IApplicationSettings> {
  const res = await repeatable(() => doRequest(`api/settings`));
  setHubUrl(res.networkSettings.hubUrl);
  return res;
}

export const claimDailyRewards = (): Promise<void> => postData(`api/rewards/daily/claims`);
export const replenish = (): Promise<IReplenish> => postData(`api/balance/replenish`);
export const watchAdvertisement = (): Promise<void> => postData(`api/ads/watch`);
export const claimRewards = (): Promise<{ assets: IClaimReferralAsset[] }> => postData(`api/referrals/claim-rewards`);
export const processTask = (id: number, payload?: string): Promise<IProcessTask> => postData(`api/tasks/${id}/process`, payload);
export const remindReferral = (id: number): Promise<void> => postData(`api/referrals/${id}/remind`);

export const getTranslations = (code: string): Promise<ITranslation[]> => repeatable(() => doRequest(`api/translations/${code}`));
export const getAccount = (): Promise<IAccount> => repeatable(() => doRequest(`api/account`));
export const getReferrals = (): Promise<IReferals> => repeatable(() => doRequest(`api/referrals`));
export const getStatistics = (): Promise<IStatistics> => repeatable(() => doRequest(`api/statistics/summary`));
export const getRanks = (type: string): Promise<IFetchedRanks> => repeatable(() => doRequest(`api/rankings/${type}`));
export const getTasks = (): Promise<IUserTask[]> => repeatable(() => doRequest(`api/tasks`));

async function refreshTokens(refreshToken: string) {
  try {
    await getToken({ grantType: 'RefreshToken', refreshToken }, true);
  } catch (e) {
    removeRefreshToken();
    removeAccessToken();
    window.location.reload();
    throw e;
  }
}

export function setAccessToken(token: string) {
  localStorage.setItem('accessToken', token);
}

export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

export function removeAccessToken() {
  localStorage.removeItem('accessToken');
}

export function setRefreshToken(token: string) {
  localStorage.setItem('refreshToken', token);
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function removeRefreshToken() {
  localStorage.removeItem('refreshToken');
}